.App {
  text-align: center;
  min-height: 700vh;
}

html {
  height: 100%;
}

body {
  padding-top: 50px;
  height: 100%;
}

#root {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.label {
  padding: 5px 0px 5px 0px;
}

.default-label {
  padding: 5px 0px 5px 0px;
}

.label-box {
  justify-content: center;
  align-content: flex-start;
}

.label-button {
  margin: 4px;
}

.labelstart {
  border-radius: 3px 0 0 3px;
  /* padding-left: 2px; */
}

.labelend {
  border-radius: 0 3px 3px 0;
  /* padding-right: 2px; */
}

.labelsingle {
  border-radius: 3px;
  /* padding-right: 2px; */
  /* padding-left: 2px; */
}

.O {
  background-color: transparent;
}

.custom-btn {
  border: 0px;
  border-radius: 3px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.sentence:nth-child(even) {
  background: #f8f8f8;
}
.sentence:nth-child(odd) {
  background: #fff;
}

.sentence {
  padding-bottom: 5px;
  line-height: 1.8;
}

.sentence-badge {
  margin-right: 7px;
}

.token {
  color: black;
  /* padding-right: 1px; */
  /* padding: 2px 0px 2px 0; */
  border: 1px solid transparent;
}

.spacer {
  border: 1px solid transparent;
  /* border-bottom: 1px solid transparent; */
  /* padding: 2px 0px 2px 0; */
}

.highlightsingle {
  border: silver solid 1px;
  border-radius: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.highlightstart {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-left: 1px solid silver;
  border-right: 0px solid transparent;
  padding-right: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px 0 0 3px;
}

.highlighted {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* border-right-color: transparent; */
  /* border-left-color: transparent; */
}

.highlightend {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  border-left: 0px solid transparent;
  padding-left: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: 1px solid silver;
  border-radius: 0 3px 3px 0;
}

.nocopy {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
}

.annotateddoc {
  background-color: green;
}

.doc-title {
  padding-top: 10px;
  font-weight: bold;
  font-size: 1.7vw;
  margin-bottom: 15px;
}

.dataset-title {
  padding-top: 10px;
}

.document-list {
  font-size: 11px;
  white-space: nowrap;

  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.document-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.document-list-panel {
  border-right: 1px solid lightgray;
  padding-right: 10px;
}

.document-list-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid lightgray;
}

.annotate-header {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 1000;
  background-color: white;
}

.check-mark {
  color: #28a745;
  justify-content: flex-end;
  flex-grow: 1;
  min-height: 20px;
  min-width: 40px;
}

.document-list-item-id {
  flex-grow: 4;
  overflow: hidden;
}

.document-list-item-id-annotated {
  background: -webkit-linear-gradient(left, #333, #333, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.extra-bottom-pad {
  padding-bottom: 150px;
}

.mymodal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1050;
}

.mymodal-main {
  position: fixed;
  background: white;
  width: 60%;
  height: auto;
  top: 50%;
  left: 50%;
  padding: 40px;
  transform: translate(-50%, -50%);
  border-radius: 10px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
